import { http } from './Connect';
import { buildUrl } from '@/helpers/buildUrl';
import {
  TStreamersListParams,
  IStreamerListResponse,
  TStreamerStats,
  TStreamerStatsPayload,
  TStreamTransactionsByGameProviderPayload,
  TStreamTransactionsPayload,
  TStreamTransactionsResponse,
  ICopyStakeReportResponse,
  ICopyStakeReportParams,
  ICopyStakeReportPdfParams,
  IStreamingScheduleListResponse,
  IStreamingScheduleListParams,
  TScheduleStreamResponse,
  ICopyStakeGamesResponse,
  ICopyStakeGamesParams,
  ICopyStakeGamesRemoveAllParams,
  ICopyStakeUsersBlacklistParam,
  ICopyStakeUsersBlacklistResponse,
  ICopyStakeStatsStreamerParams,
  ICopyStakeStatsStreamersResponse,
  DateRangeParams,
  ICopyStakeGameItemResponse,
  TCopystakeIntegration,
  TStreamConfigurationResponse,
  TCopyStakePackageResponse,
  TStreamer,
  ICopyStakeStatsResponse,
  ICopyStakeChartDataParam,
  TCopyStakeDashboardChartDataResponse,
  ICopyStakeDashboardStatsDataResponse,
  ICopyStakeDashboardViewersDataResponse,
  TCopyStakeGuidanceListResponse,
  TGuidance
} from '@/api/schema';
import { AxiosResponse, AxiosPromise } from 'axios';

const API_TRANSACTIONS = (): string => '/api/v2/tx/copy-stake';

// https://api.dev.trueplay.io/api/v1/copystake/admin/swagger-ui/index.html
const BASE = (): string => '/api/v1/copystake/control';
const BASE_OPERATOR = (): string => buildUrl(`${BASE()}/{operatorId}`);

const API_STREAMER = {
  LIST() {
    return `${BASE_OPERATOR()}/streamer`;
  },
  DELETE(id: string): string {
    return `${BASE_OPERATOR()}/streamer/${id}`;
  },
  CREATE(): string {
    return `${BASE_OPERATOR()}/streamer`;
  },
  UPDATE(id: string | number): string {
    return `${BASE_OPERATOR()}/streamer/${id}`;
  }
};

const API_TRANSACTION_STATS = (): string =>
  `${BASE_OPERATOR()}/transaction-stats`;
const API_BLACKLIST = (): string => `${BASE_OPERATOR()}/blacklist`;
const API_INTEGRATION = (): string => `${BASE_OPERATOR()}/integration`;
const API_PROVIDERS = (): string => `${BASE_OPERATOR()}/providers`;

const API_GAME = (): string => `${BASE_OPERATOR()}/streamer/game`;
const API_GAME_PERMISSION = (): string => `${API_GAME()}/permission`;

const API_SCHEDULE = (): string => `${BASE_OPERATOR()}/schedule`;

const API_CONFIG = (): string => `${BASE_OPERATOR()}/settings`;
const API_PACKAGE_CONFIG = (): string => `${API_CONFIG()}/packages`;

const API_DASHBOARD = (): string => `${BASE_OPERATOR()}/dashboard`;

const API_GUIDANCE = {
  LIST(): string {
    return `${BASE()}/guidance`; //GET
  },
  GUIDANCE(guidanceId: string | number): string {
    return `${BASE()}/guidance/${guidanceId}`; //GET
  },
  UPLOAD(): string {
    return `${BASE()}/guidance`; //POST
  },
  UPDATE(guidanceId: string | number): string {
    return `${BASE()}/guidance/${guidanceId}`; //PUT
  },
  DELETE(guidanceId: string | number): string {
    return `${BASE()}/guidance/${guidanceId}`; //DELETE
  }
};

export const getStreamConfiguration =
  (): Promise<TStreamConfigurationResponse> =>
    http
      .get(API_CONFIG())
      .then(({ data }: AxiosResponse<TStreamConfigurationResponse>) => data);

export const updateStreamConfiguration = (
  payload: Partial<TStreamConfigurationResponse>
): Promise<void> => http.put(API_CONFIG(), payload);

export const getCopyStakePackage = (): Promise<TCopyStakePackageResponse> =>
  http
    .get(API_PACKAGE_CONFIG())
    .then(({ data }: AxiosResponse<TCopyStakePackageResponse>) => data);

export const updateCopyStakePackage = (
  payload: TCopyStakePackageResponse
): Promise<TCopyStakePackageResponse> =>
  http.put(API_PACKAGE_CONFIG(), payload).then(({ data }) => data);

export const deleteStreamer = (streamerId: string): Promise<void> =>
  http.delete(API_STREAMER.DELETE(streamerId));

export const getStreamersList = (
  params: TStreamersListParams
): Promise<IStreamerListResponse> =>
  http
    .get(API_STREAMER.LIST(), { params, cacheTime: 0 })
    .then(({ data }: AxiosResponse<IStreamerListResponse>) => data);

export const createStreamer = (payload: FormData): Promise<TStreamer> =>
  http.post(API_STREAMER.CREATE(), payload).then(({ data }) => data);

export const updateStreamer = (
  id: number | string,
  payload: FormData
): Promise<TStreamer> =>
  http.put(API_STREAMER.UPDATE(id), payload).then(({ data }) => data);

export async function getStreamerOnlineLimits(): Promise<ICopyStakeStatsResponse> {
  return http
    .get(buildUrl(`${API_CONFIG()}/online-limits`))
    .then(({ data }: AxiosResponse): ICopyStakeStatsResponse => data);
}

export const getStreamStats = (
  params: TStreamerStatsPayload
): Promise<TStreamerStats> => {
  return http
    .get(API_TRANSACTION_STATS(), {
      params,
      cacheTime: 30 * 1000
    })
    .then(({ data }: AxiosResponse<TStreamerStats>) => data);
};

export async function getCopyStakeReport(
  params: ICopyStakeReportParams
): Promise<ICopyStakeReportResponse> {
  return http
    .get(`${API_PROVIDERS()}/report`, {
      params
    })
    .then(({ data }: AxiosResponse): ICopyStakeReportResponse => data);
}

export async function getCopyStakeReportPdf(
  params: ICopyStakeReportPdfParams
): Promise<any> {
  return http.get(`${API_PROVIDERS()}/report/pdf`, {
    responseType: 'blob',
    params
  });
}

export const getCopyBetStatsStreamer = (
  params: ICopyStakeStatsStreamerParams
): Promise<ICopyStakeStatsStreamersResponse> => {
  return http
    .get(`${API_TRANSACTION_STATS()}/streamer`, {
      params,
      cacheTime: 0
    })
    .then(({ data }: AxiosResponse<ICopyStakeStatsStreamersResponse>) => data);
};

export const getCopyBetStatsStreamerCsv = (
  params: DateRangeParams
): Promise<string> => {
  return http
    .get(`${API_TRANSACTION_STATS()}/streamer/csv`, {
      params
    })
    .then(({ data }: AxiosResponse<string>) => data);
};

export const getStreamingScheduleList = (
  params: IStreamingScheduleListParams
): Promise<IStreamingScheduleListResponse> =>
  http
    .get(API_SCHEDULE(), {
      params,
      cacheTime: 0
    })
    .then(({ data }: AxiosResponse<IStreamingScheduleListResponse>) => data);

export const createScheduleStream = (
  payload: FormData
): AxiosPromise<TScheduleStreamResponse> =>
  http.post(API_SCHEDULE(), payload, {
    headers: {
      'Content-Type': undefined
    }
  });

export const updateScheduleStream = (
  scheduleId: number | string,
  payload: FormData
): Promise<TScheduleStreamResponse> =>
  http.put(`${API_SCHEDULE()}/${scheduleId}`, payload, {
    headers: {
      'Content-Type': undefined
    }
  });

export const deleteScheduleStream = (scheduleId: string): Promise<void> =>
  http.delete(buildUrl(`${API_SCHEDULE()}/${scheduleId}`));

export async function getCopyStakeGames(
  params: ICopyStakeGamesParams
): Promise<ICopyStakeGamesResponse> {
  return http
    .get(API_GAME(), {
      params,
      paramsSerializer: {
        indexes: null
      },
      cacheTime: 0
    })
    .then(({ data }: AxiosResponse): ICopyStakeGamesResponse => data);
}

export async function updateCopyStakeGamePreview(
  payload: FormData,
  id: string
): Promise<ICopyStakeGameItemResponse> {
  return http
    .put(`${API_GAME()}/${id}/image`, payload)
    .then(({ data }: AxiosResponse): ICopyStakeGameItemResponse => data);
}

export async function setPermissionGame(
  enabled: boolean,
  data: number[]
): Promise<string> {
  return http
    .patch(API_GAME_PERMISSION(), data, {
      params: {
        enabled
      }
    })
    .then(({ data }: AxiosResponse): string => data);
}

export async function setPermissionGameDisabledAll(
  params: ICopyStakeGamesRemoveAllParams
): Promise<string> {
  return http
    .patch(`${API_GAME_PERMISSION()}/disable-all`, null, {
      params,
      paramsSerializer: {
        indexes: null
      }
    })
    .then(({ data }: AxiosResponse): string => data);
}

export async function getCopyStakeUsersBlacklist(
  params: ICopyStakeUsersBlacklistParam
): Promise<ICopyStakeUsersBlacklistResponse> {
  return http
    .get(API_BLACKLIST(), {
      params,
      cacheTime: 0
    })
    .then(({ data }: AxiosResponse): ICopyStakeUsersBlacklistResponse => data);
}

export async function addCopyStakeUserBlacklist(
  data: string[]
): Promise<string[]> {
  return http
    .post(API_BLACKLIST(), data)
    .then(({ data }: AxiosResponse): string[] => data);
}

export async function removeCopyStakeUserBlacklist(
  data: string[]
): Promise<string[]> {
  return http
    .delete(API_BLACKLIST(), { data })
    .then(({ data }: AxiosResponse): string[] => data);
}

export async function removeAllCopyStakeUserBlacklist(): Promise<string[]> {
  return http
    .delete(`${API_BLACKLIST()}/users`)
    .then(({ data }: AxiosResponse): string[] => data);
}

export const getCopystakeIntegration = (): Promise<TCopystakeIntegration> => {
  return http
    .get(API_INTEGRATION())
    .then(({ data }: AxiosResponse<TCopystakeIntegration>) => data);
};

export const updateCopystakeIntegration = (
  params: TCopystakeIntegration
): Promise<void> => {
  return http.put(API_INTEGRATION(), null, { params });
};

export async function getCopyStakeChartData(
  params: ICopyStakeChartDataParam
): Promise<TCopyStakeDashboardChartDataResponse> {
  return http
    .get(`${API_DASHBOARD()}/chart`, {
      params
    })
    .then(
      ({ data }: AxiosResponse): TCopyStakeDashboardChartDataResponse => data
    );
}

export async function getCopyStakeDashboardStats(
  params: DateRangeParams
): Promise<ICopyStakeDashboardStatsDataResponse> {
  return http
    .get(`${API_DASHBOARD()}/stats`, {
      params
    })
    .then(
      ({ data }: AxiosResponse): ICopyStakeDashboardStatsDataResponse => data
    );
}

export async function getCopyStakeDashboardLoyaltyStats(
  params: DateRangeParams
): Promise<ICopyStakeDashboardStatsDataResponse> {
  return http
    .get(`${API_DASHBOARD()}/stats/loyalty`, {
      params
    })
    .then(
      ({ data }: AxiosResponse): ICopyStakeDashboardStatsDataResponse => data
    );
}

export async function getCopyStakeDashboardViewers(
  params: DateRangeParams
): Promise<ICopyStakeDashboardViewersDataResponse> {
  return http
    .get(`${API_DASHBOARD()}/viewers`, {
      params
    })
    .then(
      ({ data }: AxiosResponse): ICopyStakeDashboardViewersDataResponse => data
    );
}

export const getGuidanceList = (): Promise<TCopyStakeGuidanceListResponse> =>
  http
    .get(API_GUIDANCE.LIST(), { cacheTime: 0 })
    .then(({ data }: AxiosResponse<TCopyStakeGuidanceListResponse>) => data);

export const uploadGuidance = (payload: FormData): Promise<TGuidance> =>
  http.post(API_GUIDANCE.UPLOAD(), payload).then(({ data }) => data);

export const getGuidance = (guidanceId: number | string): Promise<TGuidance> =>
  http
    .get(API_GUIDANCE.GUIDANCE(guidanceId), { cacheTime: 0 })
    .then(({ data }: AxiosResponse<TGuidance>) => data);

export const updateGuidance = (
  guidanceId: number | string,
  payload: FormData
): Promise<TGuidance> =>
  http.put(API_GUIDANCE.UPDATE(guidanceId), payload).then(({ data }) => data);

export const deleteGuidance = (guidanceId: number | string): Promise<void> =>
  http.delete(API_GUIDANCE.DELETE(guidanceId));

export const getStreamTransactions = async (
  params: TStreamTransactionsPayload
): Promise<TStreamTransactionsResponse> => {
  return http
    .get(`${API_TRANSACTIONS()}`, {
      params,
      cacheTime: 30 * 1000
    })
    .then(({ data }: AxiosResponse<TStreamTransactionsResponse>) => data);
};

export const downloadStreamCsvReport = async (
  params: TStreamTransactionsPayload
): Promise<string> => {
  return http
    .get(`${API_TRANSACTIONS()}/csv`, { params })
    .then(({ data }: AxiosResponse<string>) => data);
};

export const getStreamTransactionsByGameProvider = async (
  params: TStreamTransactionsByGameProviderPayload
): Promise<TStreamTransactionsResponse> => {
  const { data } = await http.get(`${API_TRANSACTIONS()}/game-provider`, {
    params,
    cacheTime: 30 * 1000
  });
  return data as TStreamTransactionsResponse;
};

export const downloadStreamCsvReportByGameProvider = async (
  params: TStreamTransactionsByGameProviderPayload
): Promise<string> => {
  const { data } = await http.get(`${API_TRANSACTIONS()}/game-provider/csv`, {
    params
  });
  return data as string;
};
