






















































































import { mapGetters, mapState } from 'vuex';
import { AxiosError } from 'axios';
import { DataTableHeader } from 'vuetify';

import CopyStakeUsersBlacklistModal from './CopyStakeUserBlacklist/CopyStakeUserBlacklistModal.vue';
import CopyStakeUserBlacklistConfirmation from './CopyStakeUserBlacklist/CopyStakeUserBlacklistConfirmation.vue';

import {
  addCopyStakeUserBlacklist,
  getCopyStakeUsersBlacklist,
  removeCopyStakeUserBlacklist,
  removeAllCopyStakeUserBlacklist
} from '@/api/CopyStake';
import { SelectItem, ICopyStakeUsersBlacklistItem } from '@/api/schema';

import { errorToastMessage } from '@/helpers/errorToastMessage';
import { pluralize } from '@/helpers/pluralize';

enum ESearchByOptions {
  OPERATOR_USER_ID = 'operatorUserId',
  WALLET_HASH = 'walletHash'
}

const defaultOptions = {
  page: 1,
  itemsPerPage: 10
};

export default {
  name: 'CopyStakeUserBlacklistView',
  components: {
    CopyStakeUsersBlacklistModal,
    CopyStakeUserBlacklistConfirmation
  },
  data(): any {
    return {
      options: { ...defaultOptions },
      filter: {
        searchBy: ESearchByOptions.OPERATOR_USER_ID,
        search: ''
      },
      loading: true,
      rows: [],
      totalElements: null,
      selectedUsers: [],
      openAddUsersModal: false,
      openRemoveUsersConfirmationModal: false,
      openRemoveAllUsersConfirmationModal: false
    };
  },

  computed: {
    ...mapGetters('Onboarding', ['operatorId']),
    ...mapState('app', ['isSuperAdmin']),

    canEdit(): boolean {
      return this.$role.can.update('streamers');
    },

    tableHeaders(): DataTableHeader[] {
      return [
        {
          text: 'ID',
          value: 'walletId'
        },
        {
          text: 'Registration Date',
          value: 'createdAt'
        },
        {
          text: 'Operator user ID',
          value: 'operatorUserId'
        },
        {
          text: 'Wallet address',
          value: 'walletHash'
        }
      ];
    },

    searchByOptions(): SelectItem[] {
      return [
        {
          text: 'Operator User ID',
          value: ESearchByOptions.OPERATOR_USER_ID
        },
        {
          text: 'Wallet Address',
          value: ESearchByOptions.WALLET_HASH
        }
      ];
    },

    currentSearchBy(): SelectItem {
      return this.searchByOptions.find(
        (el: SelectItem) => el.value === this.filter.searchBy
      );
    },

    searchPlaceholder(): string {
      return `Search by ${this.currentSearchBy.text}`;
    },

    requestParams(): any {
      const { searchBy, search } = this.filter;

      return {
        [searchBy]: search || null,
        page: this.options.page - 1,
        size: this.options.itemsPerPage
      };
    },

    selectedUsersIds(): string[] {
      return this.selectedUsers.map(
        (user: ICopyStakeUsersBlacklistItem) => user.operatorUserId
      );
    }
  },

  watch: {
    options: 'updateUserList',
    operatorId(): void {
      if (this.isSuperAdmin) this.updateUserList();
    }
  },

  methods: {
    updateUserList(): void {
      if (!this.operatorId) return;

      this.loading = true;

      getCopyStakeUsersBlacklist(this.requestParams)
        .then((response) => {
          this.rows = response.content;
          this.totalElements = response.totalElements;
        })
        .catch((err: AxiosError) => errorToastMessage(err))
        .finally(() => {
          this.loading = false;
        });
    },

    addUsers(users: string[]): void {
      addCopyStakeUserBlacklist(users)
        .then((failedUsers) => {
          this.updateUserList();

          const failedUsersCount = failedUsers.length;
          const addedUsersCount = users.length - failedUsersCount;

          if (addedUsersCount) {
            this.showMessage(
              'success',
              addedUsersCount,
              'added to the blacklist'
            );
          }

          if (failedUsersCount) {
            this.showMessage(
              'error',
              failedUsersCount,
              'could not be added to the blacklist'
            );
          }
        })
        .catch((err: AxiosError) => errorToastMessage(err))
        .finally(() => {
          this.openAddUsersModal = false;
        });
    },

    showMessage(type: 'success' | 'error', count: number, msg: string): void {
      const pluralizeLabel = pluralize(count, ['user', 'users', 'users']);

      this.$toast[type](`${count} ${pluralizeLabel} ${msg}`);
    },

    resetPage(): void {
      this.options.page = 1;
    },

    resetSelectedUsers(): void {
      this.selectedUsers = [];
    },

    handleClickSearchButton(): void {
      this.resetPage();
      this.updateUserList();
    },

    handleClickSearchClearButton(): void {
      this.filter.search = null;
      this.resetPage();
      this.updateUserList();
    },

    handleClickRemoveButton(): void {
      removeCopyStakeUserBlacklist(this.selectedUsersIds)
        .then(() => {
          this.resetPage();
          this.resetSelectedUsers();
          this.updateUserList();
          this.openRemoveUsersConfirmationModal = false;
        })
        .catch((err: AxiosError) => errorToastMessage(err));
    },

    handleClickRemoveAllButton(): void {
      removeAllCopyStakeUserBlacklist()
        .then(() => {
          this.resetPage();
          this.resetSelectedUsers();
          this.updateUserList();
          this.openRemoveAllUsersConfirmationModal = false;
        })
        .catch((err: AxiosError) => errorToastMessage(err));
    }
  }
};
