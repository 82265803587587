





























import { PropType } from 'vue';

import { pluralize } from '@/helpers/pluralize';

import BaseDialog from '@/components/BaseDialog.vue';
import UserIdInput from '@/components/UserIdInput.vue';

export default {
  name: 'CopyStakeUserBlacklistModal',
  components: {
    UserIdInput,
    BaseDialog
  },
  props: {
    value: {
      type: Boolean as PropType<boolean>,
      default: (): boolean => false
    }
  },

  data(): any {
    return {
      users: [],
      isValid: true
    };
  },

  computed: {
    labelCountUsers(): string {
      if (!this.users.length) return '';

      return `${this.users.length} ${pluralize(this.users.length, [
        'User',
        'Users',
        'Users'
      ])}`;
    }
  },

  watch: {
    value(open: boolean): void {
      if (!open) this.users = [];
    }
  },

  methods: {
    closeModal(): void {
      this.$emit('input', false);
    },

    handleClickAddButton(): void {
      this.$emit('add-users', this.users);
    },

    updateValidate(value: boolean): void {
      this.isValid = !value;
    }
  }
};
